<template>
  <div class="page" v-loading="pageLoading" :class="{zhijiaoPage: $route.params.plat_type =='zhijiao'}">
    <div class="position">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{ path: './detail2?activeTab=2&id=' + goods_id }"
          >{{ baseInfo ? baseInfo.name : '' }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          currentInfo.name || "课程播放"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="pos" :class="{ menuShow: menuShow, menuClose: !menuShow }">
      <div class="menu">
        <ul>
          <li
            @click="
              menuIndex = 1;
              menuShow = !menuShow;
              player.play();
            "
            :class="{ active: menuIndex == 1 }"
          >
            视频内容
          </li>
          <!-- <li
            v-if="currentInfo.handouts.length"
            @click="
              menuIndex = 2;
              menuShow = !menuShow;
            "
            :class="{ active: menuIndex == 2 }"
          >
            课程讲义
          </li>
          <li
            v-if="currentInfo.map.length"
            @click="
              menuIndex = 3;
              menuShow = !menuShow;
            "
            :class="{ active: menuIndex == 3 }"
          >
            思维导图
          </li> -->
          <p></p>
        </ul>
        <p @click="menuShow = !menuShow">
          {{ menuShow ? "收起" : "展开" }}目录
          <i
            :class="{
              'el-icon-arrow-left': menuShow,
              'el-icon-arrow-right': !menuShow,
            }"
          ></i>
        </p>
      </div>
    </div>
    <div class="playerMain">
      <div class="right">
        <div class="imgbox" v-show="menuIndex == 0">
          <h2 class="classify-title" style="font-size: 20px"></h2>
        </div>
        <div class="imgbox" v-show="menuIndex == 1">
          <h2 class="classify-title" style="font-size: 20px"></h2>
          <div id="player"></div>
        </div>
        <div class="imgbox" v-show="menuIndex == 2">
          <img
            :src="item.cover"
            v-for="(item, index) in currentInfo.handouts"
            :key="index"
          />
        </div>
        <div class="imgbox" v-show="menuIndex == 3">
          <img
            :src="item.cover"
            v-for="(item, index) in currentInfo.map"
            :key="index"
          />
        </div>
        <p class="title">{{ currentInfo.name }}</p>
        <div class="examin" v-if="examin">
          <p>随堂练习</p>
          <div class="examinContent">
            <img :src="isPass == 1 ? pass : noPass" v-if="isPass != 0" />
            <p>{{ examin.name }}</p>
            <div class="options">
              <p
                @click="examinSelect = item.id"
                :class="{ active: item.id == examinSelect }"
                v-for="item in examinOptions"
                :key="item.id"
              >
                <img :src="item.id == examinSelect ? radioAImg : radioImg" />{{
                  item.name
                }}
              </p>
            </div>
            <button
              @click="examinSubmit"
              :class="{ gray: currentInfo.test_status == 1 }"
            >
              提交
            </button>
          </div>
        </div>
        <div class="choose" v-if="list.length > 1">
          <p @click="changeVideo('pre')" :class="{ gray: currentIndex == 0 }">
            上一节
          </p>
          <p
            @click="changeVideo('next')"
            :class="{ gray: currentIndex == list.length - 1 }"
          >
            下一节
          </p>
        </div>
      </div>

      <div class="left"  v-if="$route.params.plat_type !=='zhijiao'">
        <right :list="topData" :books="books"></right>
      </div>
    </div>
  </div>
</template>

<script>
import right from "@/components/DetailRight.vue";
export default {
  name: "detail",
  components: { right },
  data() {
    return {
      menuIndex: 1,
      menuShow: false,
      books: [],
      examinOptions: [
        {
          id: 1,
          title: "选项一",
        },
      ],
      pass: "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinPass.png",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinNoPass.png",
      radioImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/radio.jpg",
      radioAImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/radioActive.jpg",
      topData: [],
      pageLoading: false,
      id: "",
      type: "",
      player: "",
      name: "",
      from: "",
      url: "",
      goods_id: "",
      videoId: "",

      duration: 0,
      currentTime: "",
      timeStart: 0,
      totalTime: 0,
      examin: null,
      examinSelect: 0,
      baseInfo: {},
      lessonId: "",
      list: [],
      lessonList: [],
      currentIndex: -1,
      currentInfo: {},
      isPass: 0,

      type: false, // false课程 true示范课
      videoType: '',
    };
  },
  watch: {
    menuIndex(e) {
      if (e != 1 && this.player) {
        this.player.pause();
      }
    },
  },
  mounted() {
    this.$router.onReady(async () => {
      this.lessonId = this.$route.query.lesson_id;
      this.goods_id = this.$route.query.id;
      // this.videoId = this.$route.query.videoId;
      // this.type = this.$route.query.type;
      // this.name = this.$route.query.name;
      // this.from = this.$route.query.from;
      // this.url = this.$route.query.url ? this.$route.query.url : "";
      this.type = this.$route.query.type;
      this.getInit();
    });
    
  },
  methods: {
    changeVideo(type) {
      if (
        (type == "pre" && this.currentIndex == 0) ||
        (type == "next" && this.currentIndex == this.list.length - 1)
      ) {
        this.$toast("没有了");
        return;
      } else {
        this.totalTime = parseInt(this.player ? this.player.getDuration() : 0);
        // this.currentTime = parseInt(
        //   this.player ? this.player.getCurrentTime() : 0
        // );
        if(this.player){
          this.currentTime = this.videoType=='cc' ? parseInt(this.player.getPosition()) : parseInt(this.player.getCurrentTime());
        }else{
          this.currentTime = 0
        }
        this.addHistory();
        this.currentIndex =
          type == "pre" ? this.currentIndex - 1 : this.currentIndex + 1;
        this.currentInfo = this.list[this.currentIndex];
        this.getVideo();
        this.getExamin();
      }
    },

    async getInit() {
      this.pageLoading = true;
      let res = await this.$request.tuoyuDetail({ goods_id: this.goods_id });
      
      this.pageLoading = false;
      if (res.code == 0) {
        this.lessonList = res.data.list;
        if (this.type) {
          this.list = res.data.demonstrate;
        } else if(this.$route.query.lessonType){
          this.list = this.flatArr(res.data.down_list, []);
        }else{
          this.list = this.flatArr(res.data.list, []);
        }
        // console.log(this.list);
        this.baseInfo = res.data.goods_info; //课程介绍
        this.baseInfo.is_buy = res.data.is_buy;
        this.baseInfo.goods_course_id = res.data.goods_course_id;
        this.baseInfo.test_id = res.data.base.test_id;
        let id =
          this.$route.query.speak_id ||
          this.$route.query.chapter_id ||
          this.$route.query.lesson_id;
        this.currentIndex = this.list.findIndex((item, index) => {
          return (
            (!item.myType && item.id == this.$route.query.chapter_id) ||
            (item.myType == "lesson" &&
              item.id == this.$route.query.lesson_id) ||
            (item.myType == "speak" && item.id == this.$route.query.speak_id)
          );
        });
        // console.log(this.currentIndex);
        this.currentInfo = this.list[this.currentIndex];
        
        // console.log(this.currentInfo.map);
        // this.addHistory()
        if(!document.getElementById('player')){
          this.$nextTick(()=>{
            this.getVideo();
          })
        }else{
          this.getVideo();
        }

        this.getExamin();
      } else {
        this.$toast(res.msg);
      }
      let res2 = await this.$request.tuoyuDetailBooks({
        goods_category_id: this.baseInfo.category_id,
      });
      if (res2.code == 0) {
        this.books = res2.data;
      } else {
        this.$toast(res.msg);
      }
    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          // console.log("lesson");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "lesson" },
          ];
          // console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          // console.log("speak");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },

    async getVideo() {
      if(!this.baseInfo.is_buy && this.currentInfo.is_try == 0){
        this.$toast(this.plat=='205' ? "如果需要观看课程，请先兑换" : "请购买后开始学习");
        this.menuIndex = 0
        // this.videoType !='cc' ? this.player.dispose() : this.player.destroy();
          return
      }
      this.menuIndex = 1
      if (this.player) {
        console.log('取消播放')
        this.videoType !='cc' ? this.player.dispose() : this.player.destroy();
      }
      let videoInfo = this.currentInfo.video_ids[0].resource[0];

      let id = videoInfo.ali_media_id;
      if (id) {
        this.videoType = 'ali'
        let res = await this.$api({
          method: "get",
          url: "/api/video/play-auth/get",
          data: {
            video_id: id,
          },
        });
        if (res.code == 0) {
          this.player = new Aliplayer(
            {
              id: "player",
              width: "100%",
              height: "480px",
              vid: res.data.videoId,
              playauth: res.data.playAuth,
              // isLive:true,
              skinLayout: [
                {
                  name: "bigPlayButton",
                  align: "blabs",
                  x: '50%',
                  y: '50%',
                },
                {
                  name: "H5Loading",
                  align: "cc",
                },
                {
                  name: "errorDisplay",
                  align: "tlabs",
                  x: 0,
                  y: 0,
                },
                {
                  name: "infoDisplay",
                },
                {
                  name: "tooltip",
                  align: "blabs",
                  x: 0,
                  y: 56,
                },
                {
                  name: "thumbnail",
                },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    {
                      name: "progress",
                      align: "blabs",
                      x: 0,
                      y: 44,
                    },
                    {
                      name: "playButton",
                      align: "tl",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "timeDisplay",
                      align: "tl",
                      x: 10,
                      y: 7,
                    },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12,
                    },
                    {
                      name: "subtitle",
                      align: "tr",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "volume",
                      align: "tr",
                      x: 5,
                      y: 10,
                    },
                  ],
                },
              ],
            },
            function (player) {
              // console.log("The player is created.");
            }
          );
        } else {
          this.$toast(res.msg);
        }
      } else if (videoInfo.url) {
        this.videoType = 'url'
        this.player = new Aliplayer(
          {
            id: "player",
            width: "100%",
            height: "480px",
            source: videoInfo.url,
            cover: "",
            // isLive:true,
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "blabs",
                x: '50%',
                y: '50%',
              },
              {
                name: "H5Loading",
                align: "cc",
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "tooltip",
                align: "blabs",
                x: 0,
                y: 56,
              },
              {
                name: "thumbnail",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  {
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44,
                  },
                  {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7,
                  },
                  {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12,
                  },
                  {
                    name: "subtitle",
                    align: "tr",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            // console.log("The player is created.");
          }
        );
      }else if(videoInfo.ccid){
        this.videoType = 'cc'
        
        this.player = window.createCCH5Player({
          vid: videoInfo.ccid,
          siteid: "23EEB4513490D389",
          // vid: 'AA22BDEE61E7455A9C33DC5901307461',
          // siteid: "2661F9756E5C832E",
          autoStart: true,
          playertype: 1,
          isShare: "false",
          banDrag: "false",
          width: "100%",
          height: "480px",
          loop: "true",
          showFullScreenBtn: "false",
          useX5Play: "true",
          isShowUrlBtn: "false",
          isShowMoreBtn: "false",
          objectFit: "cover", // contain 、 fill 、 cover
          parentNode: "#player",
          showFullScreenBtn: true
        });
        // this.player = window.createCCH5Player({
        //             vid: 'AA22BDEE61E7455A9C33DC5901307461',
        //             siteid: "2661F9756E5C832E",
        //             autoStart: true,
        //             width: "100%",
        //             height: "380px",
        //             parentNode: document.getElementById('player')
        //         });
        window.on_h5player_error = (err) => {
          console.log('失败')
          console.log(err)
        }
        window.on_CCH5player_ready = (obj)=>{
          console.log('ready')
          this.initialtime = 0

        };
        window.on_CCH5player_play = (obj) =>{
          console.log('play')
          this.videPlay()
        }
        window.on_CCH5player_pause = (obj) =>{
          console.log('videoPause')
          this.videoPause()
        }
        
      }else{
        this.$toast('暂无视频')
        return
      }
      if(this.videoType !== 'cc'){
        
        this.player.on("play", () => {
          this.videPlay()
        });
        this.player.on("pause", () => {
          this.videoPause();
        });
      }
      
    },
   
    
    async getExamin() {
      this.examin = null;
      this.isPass = 0;
      if (this.type) return;
      let param = {
        lesson_id:
          this.currentInfo.myType && this.currentInfo.myType == "lesson"
            ? this.currentInfo.id
            : "",
        chapter_id: this.currentInfo.myType ? "" : this.currentInfo.id,
        speak_id:
          this.currentInfo.myType && this.currentInfo.myType == "speak"
            ? this.currentInfo.id
            : "",
      };
      let myParam = {};
      for (let [k, v] of Object.entries(param)) {
        if (v) {
          myParam[k] = v;
        }
      }
      let res = await this.$request.tuoyuExamin(myParam);
      if (res.code == 0) {
        if (!res.data || !res.data.id) {
          return;
        }
        this.examin = res.data;

        this.examinOptions = [];
        this.examinSelect = -1;

        if (this.examin.type == 1) {
          //选择题
          if (this.examin.item_one) {
            this.examinOptions.push({
              name: this.examin.item_one,
              id: 1,
            });
          }
          if (this.examin.item_two) {
            this.examinOptions.push({
              name: this.examin.item_two,
              id: 2,
            });
          }
          if (this.examin.item_three) {
            this.examinOptions.push({
              name: this.examin.item_three,
              id: 3,
            });
          }
          if (this.examin.item_four) {
            this.examinOptions.push({
              name: this.examin.item_four,
              id: 4,
            });
          }
          if (this.currentInfo.test_status == 1) {
            //已通过测试，直接显示
            this.isPass = 1;
            this.examinSelect = this.examin.answer;
          }
        } else {
          this.examinOptions = [
            {
              name: "正确",
              id: 1,
            },
            {
              name: "错误",
              id: 2,
            },
          ];
          if (this.currentInfo.test_status == 1) {
            //已通过测试，直接显示
            this.isPass = 1;
            this.examinSelect = this.examin.judge;
          }
        }
      } else {
        this.$toast(res.msg);
      }
    },
    async examinSubmit() {
      if (this.currentInfo.test_status == 1) {
        return;
      }
      if (this.examinSelect == -1) {
        this.$toast("请选择答案");
        return;
      }
      let param = {
        test_id: this.examin.id,
        type: this.examin.type,
      };
      if (this.examin.type == 1) {
        //选择题
        param.item = this.examinSelect;
      } else {
        param.judge = this.examinSelect;
      }
      this.pageLoading = true;
      let res = await this.$request.tuoyuExaminSubmit(param);
      this.pageLoading = false;
      if (res.code == 0) {
        console.log(res);
        if (this.examin.type == 1) {
          this.isPass = res.data.choose_answer == this.examinSelect ? 1 : 2;
        } else {
          this.isPass = res.data.judge_answer ? 1 : 2;
        }
        this.currentInfo.test_status = this.isPass;
      } else {
        this.$toast(res.msg);
      }
    },
    videPlay() {
      console.log("播放");
      // console.log(this.player.getDuration())
      this.totalTime = parseInt(this.player.getDuration());
      this.timeStart = new Date();
    },
    videoPause() {
      console.log("暂停11");
      this.totalTime = parseInt(this.player.getDuration());
      this.currentTime = this.videoType=='cc' ? parseInt(this.player.getPosition()) : parseInt(this.player.getCurrentTime());

      this.addHistory();
    },
    async addHistory() {
      if (this.type) return;
      console.log("history");
      let data = this.currentInfo;
      this.duration = this.timeStart
        ? parseInt((new Date().getTime() - this.timeStart.getTime()) / 1000)
        : 0;
      console.log(data);
      console.log(this.duration);
      // if(!data || this.duration<5) return
      this.timeStart = 0;
      let res = await this.$request.tuoyuRecord({
        lesson_id:
          this.currentInfo.myType && this.currentInfo.myType == "lesson"
            ? this.currentInfo.id
            : 0,
        chapter_id: this.currentInfo.myType ? 0 : this.currentInfo.id,
        speak_id:
          this.currentInfo.myType && this.currentInfo.myType == "speak"
            ? this.currentInfo.id
            : 0,
        goods_course_id: this.baseInfo.goods_course_id,
        status: this.currentTime && this.currentTime === this.totalTime ? 1 : 0,
        duration: this.duration,
        flat_id: this.plat,
        playmode_id: this.currentInfo.video_ids[0].id,
      });
      // if (res.code == 0) {
      //   console.log(res.data);
      // }
    },
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
    // currentInfo(){
    //   return this.$store.state.currentVideoInfo
    // }
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate");
    // console.log(to);
    // this.id = to.query.id;
    // this.getData();
    this.id = this.$route.query.id;
    this.goods_id = to.query.goods_id;
    this.videoId = to.query.videoId;
    this.type = to.query.type;
    this.name = to.query.name;
    this.from = to.query.from;
    this.url = to.query.url ? to.query.url : "";
    this.player.dispose();
    this.getInit();
    next();
  },
};
</script>
<style>
.prism-progress{
  display: none;
}
body {
  background: #fff;
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #333;
  font-size: 14px;
  font-weight: normal;
}
.el-breadcrumb__separator {
  color: #adadad;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #333;
  font-size: 14px;
  font-weight: normal;
}
</style>
<style lang="scss" scoped>
@import url("https://service.wormhoo.com/libs/js/vant2/index.css");
.zhijiaoPage{
  width: 800px;
  margin: 0 auto;
}
.gray {
  background: #d6d6d6 !important;
  color: #b3b3b3 !important;
}
.page {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  // padding-top: 60px;
  .position {
    padding-top: 40px;
    background: #fff;
    padding-bottom: 25px;
  }
  .pos {
    display: none;
    position: absolute;
    // top: 64px;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 1218px;
    // width: 220px;
    z-index: 9999;
    // overflow: hidden;
  }
  .menu {
    z-index: 9999;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    position: relative;
    width: 180px;
    transition: all 0.3s;
    ul {
      height: 480px;
      width: 180px;
      overflow: hidden;
      text-align: center;
      display: flex;
      flex-direction: column;
      li {
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        background: rgba($color: #000000, $alpha: 0.6);
        border-bottom: 1px solid #fff;
      }
      p {
        flex: 1;
        background: rgba($color: #000000, $alpha: 0.6);
      }
      li:hover,
      .active {
        background: rgba($color: #ffffff, $alpha: 0.6);
        color: #343434;
      }
    }

    > p {
      position: absolute;
      right: -38px;
      top: 50%;
      transform: translateY(-50%);
      background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/menu.png");
      width: 38px;
      height: 145px;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;
    }
  }
  .menuShow {
    left: 0;
  }
  .menuClose {
    left: -180px;
  }

  .title {
    font-size: 24px;
    padding-top: 34px;
    padding-bottom: 40px;
  }
  .playerMain {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .left {
    width: 360px;
    overflow: hidden;
  }

  .classify {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    flex: 0 0 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;

    .classify-title {
      font-size: 20px;
      padding: 0 20px 0;
      line-height: 1;
    }

    .classify-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    .tip {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      img {
        width: 40px;
      }
    }
    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img {
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    width: 800px;
    // height: 480px;
    // overflow: scroll;

    h2 {
      margin: 0;
    }
    .examin {
      background: #f9f9f9;
      border-radius: 10px;
      padding: 0 30px;
      margin-bottom: 20px;
      > p {
        text-align: center;
        border-bottom: 1px dashed #d2d2d2;
        font-size: 24px;
        padding-top: 34px;
        padding-bottom: 24px;
      }
      .examinContent {
        padding: 20px 0;
        position: relative;
        > img {
          position: absolute;
          top: 31px;
          right: 31px;
        }
        > p {
          font-size: 20px;
          padding-top: 10px;
          padding-bottom: 20px;
        }
        .options {
          padding-left: 14px;
          p {
            display: flex;
            align-items: center;
            font-size: 16px;
            padding: 10px 0;
            cursor: pointer;
            img {
              margin-right: 10px;
            }
          }
          p.active {
            background: none;
            color: #0080d6;
          }
        }
        > button {
          width: 220px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          background: #0080d6;
          color: #fff;
          border: 0;
          border-radius: 5px;
          margin: 30px auto;
          display: block;
          cursor: pointer;
        }
      }
    }
    .choose {
      display: flex;
      justify-content: flex-end;
      p {
        cursor: pointer;
        width: 122px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        border-radius: 3px;
        border: 0;
        &:first-of-type {
          background: #0080d6;
          color: #fff;
        }
        &:nth-of-type(2) {
          margin-left: 20px;
          background: #f9f9f9;
          color: #0080d6;
        }
      }
      .active {
        color: #0080d6;
      }
      .gray {
        background: #e4e4e4;
        border: 1px solid #e4e4e4;
        color: #666;
      }
    }
  }

  #player {
    width: 100%;
    height: 480px;
    padding-bottom: 0;
    background: #000;
  }

  .imgbox {
    height: 480px;
    overflow: auto;
  }
}
</style>

<style lang="scss">
.prism-player .prism-big-play-btn{
  transform: translate(-50%, 50%);
}
video {
  height: 480px;
}
</style>
